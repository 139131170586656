import { ethers } from "ethers";
import { getContract } from "./contracts";
//import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, GOERLI_TESTNET, POLYGON, MAINNET, TESTNET } from "./chains";
import { POLYGON } from "./chains";
import { Token } from "domain/tokens";

export const TOKENS: { [chainId: number]: Token[] } = {

  [POLYGON]: [
    {
      name: "ZetaChain",
      symbol: "ZETA",
      graphSymbol: "ZETA",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      //imageUrl: "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
      imageUrl: "https://s3.coinmarketcap.com/static-gravity/image/901279d4611c458098fccee1c6076e36.png",
    },
    {
      name: "Wrapped ZETA",
      symbol: "WZETA",
      graphSymbol: "WZETA",
      decimals: 18,
      address: "0x5f0b1a82749cb4e2278ec87f8bf6b618dc71a8bf",
      isWrapped: true,
      baseSymbol: "ZETA",
      //imageUrl: "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
      imageUrl: "https://s3.coinmarketcap.com/static-gravity/image/901279d4611c458098fccee1c6076e36.png",
    },
    {
      //name: "ZetaChain ETH",
      name: "sETH",
      symbol: "sETH",
      graphSymbol: "sETH",
      address: "0x7d1d1600f11e2fea128d624628accacd4bfa019e",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://storage.googleapis.com/eddy-finance/assets/tokens/native-tokens/eth-zeta.svg",
    },
    {
      name: "sBTC",
      symbol: "sBTC",
      graphSymbol: "sBTC",
      address: "0x8ffad308b269307fc05977ba971fe4434d2914b1",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://storage.googleapis.com/eddy-finance/assets/tokens/native-tokens/bitcoin-zeta.svg",
    },

    {
      name: "sBNB",
      symbol: "sBNB",
      graphSymbol: "sBNB",
      address: "0x7d54075eafa3bbd8085492cf7c0f4c9a89bb8c8e",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://storage.googleapis.com/eddy-finance/assets/tokens/native-tokens/bnb-zeta.svg",
    },

    {
      name: "sUSDC",
      symbol: "sUSDC",
      graphSymbol: "sUSDC",
      address: "0x96b4d2d47354a7ee0b8ed8f01c7a258148d8107e",
      decimals: 6,
      isStable: true,
      imageUrl: "https://s3.openocean.finance/token_logos/logos/1694159850673_01787531906169937.png",
    },
    {
      name: "sUSDT",
      symbol: "sUSDT",
      graphSymbol: "sUSDT",
      address: "0xf7d40ca1241b0fc1fa9acb7c22b981722b3ead23",
      decimals: 6,
      isStable: true,
      imageUrl: "https://raw.githubusercontent.com/trustwallet/asse…dAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    },
    // {
    //   name: "BNB.BSC",
    //   symbol: "BNB.BSC",
    //   graphSymbol: "BNBBSC",
    //   address: "0xd97b1de3619ed2c6beb3860147e30ca8a7dc9891",
    //   decimals: 18,
    //   isShortable: true,
    //   imageUrl: "https://storage.googleapis.com/eddy-finance/assets/tokens/native-tokens/bnb-zeta.svg",
    // },
    // {
    //   name: "BTC.BTC",
    //   symbol: "BTC.BTC",
    //   graphSymbol: "BTCBTC",
    //   address: "0x65a45c57636f9bcced4fe193a602008578bca90b",
    //   decimals: 8,
    //   isShortable: true,
    //   imageUrl: "https://storage.googleapis.com/eddy-finance/assets/tokens/native-tokens/bitcoin-zeta.svg",
    // },

    // {
    //   name: "USDC.SEPOLIA",
    //   symbol: "USDC.ETH",
    //   graphSymbol: "USDCETH",
    //   address: "0xcc683a782f4b30c138787cb5576a86af66fdc31d",
    //   decimals: 6,
    //   isStable: true,
    //   imageUrl: "https://s3.openocean.finance/token_logos/logos/1694159850673_01787531906169937.png",
    // },
    // {
    //   name: "USDC.BSC",
    //   symbol: "USDC.BSC",
    //   graphSymbol: "USDCBSC",
    //   address: "0x7c8dda80bbbe1254a7aacf3219ebe1481c6e01d7",
    //   decimals: 6,
    //   isStable: true,
    //   imageUrl: "https://s3.openocean.finance/token_logos/logos/1694159850673_01787531906169937.png",
    // },

    // {
    //   name: "ZetaChain USDT.BSC",
    //   symbol: "USDT.BSC",
    //   address: "0x9cbfbf2cdba16a4ed28f629115202b41be5a49e3",
    //   decimals: 6,
    //   isStable: true,
    //   imageUrl: "https://s3.openocean.finance/token_logos/logos/1694159923013_9360200848023177.png",
    // },
    // {
    //   name: "ZetaChain USDT.ETH",
    //   symbol: "USDT.ETH",
    //   address: "0x9cbfbf2cdba16a4ed28f629115202b41be5a49e3",
    //   decimals: 6,
    //   isStable: true,
    //   imageUrl: "https://s3.openocean.finance/token_logos/logos/1694159923013_9360200848023177.png",
    // },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {

  [POLYGON]: [
    {
      name: "SBX",
      symbol: "SBX",
      address: getContract(POLYGON, "GMX"),
      decimals: 18,
      imageUrl: "",
    },
    {
      name: "Escrowed SBX",
      symbol: "esSBX",
      address: getContract(POLYGON, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "SBX LP",
      symbol: "SBLP",
      address: getContract(POLYGON, "GLP"),
      decimals: 18,
      imageUrl: "",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {

  [POLYGON]: {
    // POLYGON
    GMX: {
      name: "Sbxespade",
      symbol: "SBX",
      decimals: 18,
      address: getContract(POLYGON, "GMX"),
      imageUrl: "",
    },
    GLP: {
      name: "SBX LP",
      symbol: "SBLP",
      decimals: 18,
      address: getContract(POLYGON, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "",
    },
  },
};

export const ICONLINKS = {
  [POLYGON]: {
    GMX: {
      polygon: "https://athens.explorer.zetachain.com/address/0x70e46adCAD0151a8BADAB1B1A82A871ee1324425",
    },
    GLP: {
      polygon: "https://athens.explorer.zetachain.com/address/0xE50c4a68fe2799979c7008Ca682d508b50CfA201",
    },
    WZETA: {
      coingecko: "https://www.coingecko.com/en/coins/zetachain",
      polygon: "https://athens.explorer.zetachain.com/address/0x5f0b1a82749cb4e2278ec87f8bf6b618dc71a8bf",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      polygon: "https://athens.explorer.zetachain.com/address/0x65a45c57636f9bcced4fe193a602008578bca90b",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      polygon: "https://athens.explorer.zetachain.com/address/0x05ba149a7bd6dc1f937fa9046a9e05c05f3b18b0",
    },
    BNB: {
      coingecko: "https://www.coingecko.com/en/coins/bnb",
      polygon: "https://athens.explorer.zetachain.com/address/0xd97b1de3619ed2c6beb3860147e30ca8a7dc9891",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      polygon: "https://athens.explorer.zetachain.com/address/0xcc683a782f4b30c138787cb5576a86af66fdc31d",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      polygon: "https://athens.explorer.zetachain.com/address/0xcc683a782f4b30c138787cb5576a86af66fdc31d",
    },
    sBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      polygon: "https://athens.explorer.zetachain.com/address/0x65a45c57636f9bcced4fe193a602008578bca90b",
    },
    sETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      polygon: "https://athens.explorer.zetachain.com/address/0x05ba149a7bd6dc1f937fa9046a9e05c05f3b18b0",
    },
    sBNB: {
      coingecko: "https://www.coingecko.com/en/coins/bnb",
      polygon: "https://athens.explorer.zetachain.com/address/0xd97b1de3619ed2c6beb3860147e30ca8a7dc9891",
    },
    sUSDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      polygon: "https://athens.explorer.zetachain.com/address/0xcc683a782f4b30c138787cb5576a86af66fdc31d",
    },
    sUSDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      polygon: "https://athens.explorer.zetachain.com/address/0xcc683a782f4b30c138787cb5576a86af66fdc31d",
    },
  },
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  MATIC: "#AAAAFF",
  WBTC: "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  USDT: "#67B18A",
  MIM: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  LINK: "#3256D6",
  CRO: "#AAAAFF",
  ZETA: "#005741",
  WZETA: "#dcfe76",
  ETHETH: "#6062a6",
  BTCBTC: "#F7931A",
  USDCETH: "#2775CA",
  USDCBSC: "#2775CA",
  BNBBSC: "#d9e900",
  sETH: "#6062a6",
  sBTC: "#F7931A",
  sUSDC: "#2775CA",
  sUSDT: "#67B18A",
  tBNB: "#d9e900",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

//const CHAIN_IDS = [MAINNET, TESTNET, ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, GOERLI_TESTNET, POLYGON];
const CHAIN_IDS = [POLYGON];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];

    // TODO kani
    //console.log("◆ Token setting:", token.address, token.symbol)
    const address = token.address.toLowerCase();
    TOKENS_MAP[chainId][address] = token;
    //TOKENS_MAP[chainId][token.address] = token;

    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  address = address.toLowerCase();

  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}
