// import { ARBITRUM, AVALANCHE, GOERLI_TESTNET, POLYGON } from "./chains";
import { POLYGON } from "./chains";

export const SUBGRAPH_URLS = {
  [POLYGON]: {
    //stats: "https://api.thegraph.com/subgraphs/name/uupay1/sobax-polygon-stats",
    //referrals: "https://api.thegraph.com/subgraphs/name/uupay1/sobax-polygon-referrals",
    //trades: "https://api.thegraph.com/subgraphs/name/uupay1/sobax-polygon-trades",
    // stats: "http://localhost:8000/subgraphs/name/ganache/sobax-ganache-stats",
    // referrals: "http://localhost:8000/subgraphs/name/ganache/sobax-ganache-referrals",
    // trades: "http://localhost:8000/subgraphs/name/ganache/sobax-ganache-trades",
    // stats: "http://localhost:9000/subgraphs/name/zeta/sobax-zeta-test-trade-stats",
    // referrals: "http://localhost:9000/subgraphs/name/zeta/sobax-zeta-test-trade-referrals",
    // trades: "http://localhost:9000/subgraphs/name/zeta/sobax-zeta-test-trade-trades",
    stats: "https://graph-testnet.sobax.app/subgraphs/name/zeta/sobax-zeta-test-trade-stats",
    referrals: "https://graph-testnet.sobax.app/subgraphs/name/zeta/sobax-zeta-test-trade-referrals",
    trades: "https://graph-testnet.sobax.app/subgraphs/name/zeta/sobax-zeta-test-trade-trades"

  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
